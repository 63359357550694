<template>
    <div>
        <div>
            <label class="font-weight-semibold">
                {{ $trans('Answer') }}:
            </label>
            <em>
                <template
                    v-for="(char) in value.text"
                >
                    <template
                        v-if="char && char.startsWith('__input__')"
                    >
                        <b :key="char">{{ reply[char] }}</b>
                    </template>
                    <template
                        v-else
                    >
                        {{ char }}
                    </template>
                </template>
            </em>
        </div>
        <div v-if="!isPassed" class="mt-3">
            <label class="font-weight-semibold">
                {{ $trans('Correct') }}:
            </label>
            <em>
                <template
                    v-for="(char) in value.text"
                >
                    <template
                        v-if="char && char.startsWith('__input__')"
                    >
                        <b :key="char">{{ value.correct[char] }}</b>
                    </template>
                    <template
                        v-else
                    >
                        {{ char }}
                    </template>
                </template>
            </em>
        </div>
    </div>
</template>

<script>
import questionsTypeBasePropsMixin from './mixins/questionsTypeBasePropsMixin'
import _ from 'lodash'

export default {
    name: 'FillTextResult',
    mixins: [questionsTypeBasePropsMixin],
    computed: {
        isPassed() {
            const correct = this.value.correct
            const reply = this.reply

            return _.isEqual(correct, reply)
        },
    }
}
</script>

<style lang=scss>

</style>
